import ComponentList from "../ComponentList";
import LocaleLink from "../../Navigation/LocaleLink";
import ProductFilterSubmenuButton from "./ProductFilterSubmenuButton";
import React from "react";
import useTranslation from "next-translate/useTranslation";

const SubCategory = (props) => {
  const { subCategoryName, href, name, availableCategories } = props;
  const { t } = useTranslation("categories");

  const categoryEntry = availableCategories.find((o) => o.key === subCategoryName);

  const count = categoryEntry?.count;

  return (
    <LocaleLink notStyled href="/[...generic]" as={href}>
      <ProductFilterSubmenuButton name={t(name)} count={count} />
    </LocaleLink>
  );
};

const ProductCategorySubCategories = ({ category, availableCategories }) => {
  return (
    <ComponentList
      components={category.children.map((child, i) => {
        const subCategoryName = `${category.pathNames.join(" > ")} > ${child.name}`;
        return (
          <SubCategory
            key={i}
            subCategoryName={subCategoryName}
            href={`/${child.id}`}
            name={child.name}
            availableCategories={availableCategories}
          />
        );
      })}
    />
  );
};

export default ProductCategorySubCategories;
