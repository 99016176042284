import ComponentList from "../ComponentList";
import ProductFilterSubmenuButton from "./ProductFilterSubmenuButton";
import environment from "../../../environment";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const useStyles = makeStyles((theme) => ({
  reset: {
    color: theme.unflashyColor,
    "& span": {
      fontWeight: 300,
    },
  },
  closeIcon: {
    display: "inline-block",
    float: "left",
    marginRight: 5,
    height: 18,
    width: 14,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: "url('/images/Delete.svg')",
  },
}));

const defaultPriceRange = [environment.products.minimumPrice, environment.products.maximumPrice];

const ProductFilterActiveBar = (props) => {
  const { values, onFilter, onReset, defaultSort } = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("common");
  const { priceAsString } = useTranslationExtras(t, lang);

  const closeIcon = <span className={classes.closeIcon} />;

  const brands = values.brands
    ? values.brands.map((brand) => {
        return {
          name: brand,
          startIcon: closeIcon,
          onClick: () => {
            onFilter({
              ...values,
              brands: values.brands.filter((entry) => entry != brand),
            });
          },
        };
      })
    : [];

  const categories = values.categories
    ? values.categories.map((category) => {
        return {
          name: category.split(" > ").pop(),
          startIcon: closeIcon,
          onClick: () => {
            onFilter({
              ...values,
              categories: values.categories.filter((entry) => entry != category),
            });
          },
        };
      })
    : [];

  const showPriceRange = values.priceRange && values.priceRange.join(",") != defaultPriceRange.join(",");
  const priceRange = showPriceRange
    ? {
        name: t("productFilterPriceRange", {
          min: priceAsString(
            {
              amount: values.priceRange[0],
              decimalOffset: 0,
            },
            0,
            true,
            true
          ),
          max: priceAsString(
            {
              amount: values.priceRange[1],
              decimalOffset: 0,
            },
            0,
            false,
            true
          ),
        }),
        startIcon: closeIcon,
        onClick: () => {
          onFilter({
            ...values,
            priceRange: defaultPriceRange,
          });
        },
      }
    : null;

  const showSorting = values.sorting && values.sorting != defaultSort;
  const sorting = showSorting
    ? {
        name: t(`productFilterSort_${values.sorting}`),
        startIcon: closeIcon,
        onClick: () => {
          onFilter({
            ...values,
            sorting: defaultSort,
          });
        },
      }
    : null;

  const showCondition = !!values.condition;
  const condition = showCondition
    ? {
        name: t(`productFilterCondition_${values.condition}`),
        startIcon: closeIcon,
        onClick: () => {
          onFilter({
            ...values,
            condition: null,
          });
        },
      }
    : null;

  const resetAll = {
    name: t("productFilterResetAll"),
    className: classes.reset,
    onClick: () => {
      onReset && onReset();
    },
  };

  const components = [...brands, ...categories, priceRange, sorting, condition, resetAll].filter((n) => n);

  return (
    <ComponentList
      components={components.map((child, i) => {
        return (
          child && (
            <ProductFilterSubmenuButton
              key={i}
              name={child.name}
              count={child.count}
              className={child.className}
              onClick={child.onClick}
              startIcon={child.startIcon}
            />
          )
        );
      })}
    />
  );
};

export default ProductFilterActiveBar;
