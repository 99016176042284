import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDebounce, useEffectOnce } from "react-use";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionIcon from "../../Navigation/Icons/AccordionIcon";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Analytics from "../../../lib/analytics/analytics";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";
import FilterIcon from "../../Navigation/Icons/FilterIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import ListWithFilter from "../ListWithFilter";
import OpenIcon from "../../Navigation/Icons/OpenIcon";
import PlusIcon from "../../Navigation/Icons/Plus";
import Slider from "@material-ui/core/Slider";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import environment from "../../../environment";
import { default as productCategories } from "../../../categoriesExplodedTree.json";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  drawer: {
    "&.MuiDrawer-root": {
      top: `${theme.menuBarHeight}px !important`,
    },
    "& .MuiDrawer-paperAnchorLeft": {
      overflowX: "hidden",
    },
    "& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft": {
      marginTop: theme.menuBarHeight,
      width: "100%",
    },
    "& .MuiBackdrop-root": {
      marginTop: theme.menuBarHeight,
    },
    "& .MuiDrawer-paper": {
      backgroundColor: theme.palette.background.default,
    },
  },
  drawerContent: {
    width: "100%",
    // minHeight: '100%',
    // minHeight: '50vh',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.globalMarginMobile,
    paddingRight: theme.globalMarginMobile,
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(30),
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  title: {
    display: "inline-block",
    fontSize: "x-large",
    fontWeight: 900,
  },
  resetButton: {
    border: `1px solid ${theme.filterColor} !important`,
    backgroundColor: theme.palette.background.secondary,
    paddingTop: 6,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    fontSize: "medium",
    fontWeight: 300,
    display: "inline-block",
  },
  showButton: {
    backgroundColor: theme.filterColor,
    color: theme.palette.primary.main,
    fontSize: "large",
    fontWeight: 900,
    paddingLeft: 15,
    paddingRight: 15,
    display: "inline-block",
  },
  button: {
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.unflashyColor}`,
    fontWeight: 300,
    fontSize: "medium",
    paddingTop: 2,
    paddingBottom: 2,
  },
  showButtonContainer: {
    position: "fixed",
    width: "100%",
    textAlign: "center",
    bottom: 10,
    left: 0,
  },
  showButtonIcon: {
    fontSize: "12px !important",
    filter: "invert(100%)",
    // marginBottom: -3
  },
  headline: {
    fontWeight: 900,
    fontSize: "large",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  sortContainer: {
    display: "flex",
    overflow: "auto",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  sortItem: {
    flex: "0 0 auto",
    marginRight: theme.spacing(1),
  },
  sortItemDiscount: {
    color: theme.saleColor,
    borderColor: theme.saleColor,
  },
  sortItemDiscountActive: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.saleColor}!important`,
  },
  sortItemActive: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: theme.palette.primary.main,
  },
  settingContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divider: {
    height: 1,
    backgroundColor: theme.unflashyColor,
  },
  priceInput: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.shape.borderRadius,
    width: 85,
    paddingLeft: 5,
    paddingRight: 10,
    marginBottom: 8,
    border: `1px solid ${theme.unflashyColor}`,
    "& .MuiInputBase-inputMarginDense": {
      paddingTop: 5,
      paddingBottom: 5,
    },
    "&::after": {
      content: "'€'",
      fontWeight: 400,
    },
  },
  priceSliderContainer: {
    marginLeft: 12,
    marginRight: 12,
  },
  expansionPanel: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
    "& .MuiAccordionSummary-root.Mui-focused": {
      backgroundColor: theme.palette.background.default,
    },
  },
  accordionSummary: {
    alignItems: "flex-start",
    "& .MuiAccordionSummary-content": {
      display: "flex",
      flexDirection: "column",
    },
  },
  accordionContent: {
    width: "100%",
    paddingRight: 36,
  },
  accordionPreview: {
    width: "100%",
  },
  filterCheckbox: {
    width: "100%",
    maxWidth: 350,
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  filterList: {
    width: "100%",
    "& .FX-ListWithFilter-Content": {
      maxHeight: 213,
      overflowX: "hidden",
      overflowY: "auto",
    },
    "& .FX-ListWithFilter-SearchInput": {
      backgroundColor: theme.palette.background.secondary,
      borderRadius: theme.shape.borderRadius,
    },
  },
  count: {
    marginLeft: 5,
    float: "right",
    color: theme.unflashyColor,
  },
  categoryButton: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  categoryButtonActive: {
    backgroundColor: theme.toggleButtonActive,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.toggleButtonActive,
      color: theme.palette.primary.main,
    },
    "& .FX-ProductFilterCategoryCount": {
      color: theme.toggleButtonActiveUnflashy,
    },
  },
  categoryName: {},
  categoryCount: {
    marginLeft: 5,
    float: "right",
    color: theme.unflashyColor,
    fontWeight: 300,
  },
  showMore: {
    display: "flex",
    alignItems: "center",
    color: theme.openButtonColor,
    "& .MuiIcon-root": {
      filter: theme.openButtonColorFromBlack,
    },
  },
  showMoreIcon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
}));

function priceValuetext(value) {
  return `${value}`;
}

const StyledSlider = withStyles((theme: TheCycleverseTheme) => {
  return {
    root: {
      color: theme.unflashyColor,
      height: 2,
      padding: "15px 0",
    },
    thumb: {
      height: 28,
      width: 28,
      border: `1px solid ${theme.unflashyColor}`,
      backgroundColor: "#fff",
      marginTop: -14,
      marginLeft: -14,
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 11px)",
      top: -22,
      "& *": {
        background: "#fff", //'transparent',
        color: "#000",
      },
    },
    track: {
      height: 4,
    },
    rail: {
      height: 2,
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  };
})(Slider);

const getDefaultBrandsSelected = (brands, availableBrands) => {
  let defaultBrandsSelected = {};
  if (availableBrands) {
    availableBrands.map((entry) => {
      const brand = entry.key;
      if (brands) {
        defaultBrandsSelected[brand] = brands.indexOf(brand) >= 0;
      } else {
        defaultBrandsSelected[brand] = false;
      }
    });
  }
  return defaultBrandsSelected;
};

const getDefaultCategoriesSelected = (categories, availableCategories) => {
  let defaultCategoriesSelected = {};
  if (availableCategories) {
    availableCategories.map((entry) => {
      const category = entry.key;
      if (categories) {
        defaultCategoriesSelected[category] = categories.indexOf(category) >= 0;
      } else {
        defaultCategoriesSelected[category] = false;
      }
    });
  }
  return defaultCategoriesSelected;
};

const ProductFilter = ({
  buttonClass,
  values,
  onFilter,
  onReset,
  availableBrands,
  availableCategories,
  hideCounts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const router = useRouter();
  const maximumPrice = environment.products.maximumPrice;
  const minimumPrice = environment.products.minimumPrice;
  const defaultPriceRange = useMemo(() => [minimumPrice, maximumPrice], [minimumPrice, maximumPrice]);
  const [expanded, setExpanded] = useState(false);
  const [sorting, setSorting] = useState(values.sorting);
  const [condition, setCondition] = useState(values.condition);
  const [priceRange, setPriceRange] = useState(values.priceRange ? values.priceRange : defaultPriceRange);
  const [brandAccordionExpanded, setBrandAccordionExpanded] = useState(false);
  const [brandSearchValue, setBrandSearchValue] = useState();
  const [brandsSelected, setBrandsSelected] = useState(getDefaultBrandsSelected(values.brands, availableBrands));
  const [categoriesSelected, setCategoriesSelected] = useState(
    getDefaultCategoriesSelected(values.categories, availableCategories)
  );
  const category = productCategories[router.asPath?.split("?")[0]?.replace("/", "")];
  const showProductConditionFilter = category?.path?.startsWith("Fahrräder") || category?.path?.startsWith("E-Bikes");
  const sendResponse = (params) => {
    const { sorting, condition, priceRange, brands, categories } = params;
    onFilter({
      sorting,
      condition,
      priceRange,
      brands,
      categories,
    });
  };

  const reset = () => {
    setExpanded(false);
    setSorting(values.sorting);
    setCondition(values.condition);
    setBrandsSelected(getDefaultBrandsSelected(values.brands, availableBrands));
    setPriceRange(values.priceRange);
    setCategoriesSelected(getDefaultCategoriesSelected(values.categories, availableCategories));
    onReset && onReset();
  };

  useEffect(() => {
    setSorting(values.sorting);
    setCondition(values.condition);
    setPriceRange(values.priceRange ? values.priceRange : defaultPriceRange);
    setBrandsSelected(getDefaultBrandsSelected(values.brands, availableBrands));
  }, [values, availableBrands, availableCategories, defaultPriceRange]);

  useEffectOnce(() => {
    if (values.sorting != sorting) {
      setSorting(sorting);
    }
    if (values.condition != condition) {
      setCondition(condition);
    }
    if (values.priceRange != priceRange) {
      setPriceRange(values.priceRange);
    }
    const brandsDefault = getDefaultBrandsSelected(values.brands, availableBrands);
    if (brandsDefault != brandsSelected) {
      setBrandsSelected(brandsDefault);
    }
    if (getDefaultCategoriesSelected(values.categories, availableCategories) != categoriesSelected) {
      setCategoriesSelected(getDefaultCategoriesSelected(values.categories, availableCategories));
    }
  });

  const show = () => {
    sendResponse({
      sorting,
      condition,
      priceRange,
      brands: Object.keys(brandsSelected).filter((k) => brandsSelected[k] === true),
      categories: Object.keys(categoriesSelected).filter((k) => categoriesSelected[k] === true),
    });
    setExpanded(false);
  };

  const handleSliderChangePrice = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleInputChangePriceMin = (event) => {
    if (event.target.value !== "") {
      const value = Number(event.target.value);
      setPriceRange([value, priceRange[1]]);
    }
  };

  const handleInputChangePriceMax = (event) => {
    if (event.target.value !== "") {
      const value = Number(event.target.value);
      setPriceRange([priceRange[0], value]);
    }
  };

  const handleBlurPriceMin = () => {
    if (priceRange[0] < minimumPrice) {
      setPriceRange([minimumPrice, priceRange[1]]);
    } else if (priceRange[0] > maximumPrice) {
      setPriceRange([maximumPrice, priceRange[1]]);
    }
  };

  const handleBlurPriceMax = () => {
    if (priceRange[1] < minimumPrice) {
      setPriceRange([priceRange[0], minimumPrice]);
    } else if (priceRange[1] > maximumPrice) {
      setPriceRange([priceRange[0], maximumPrice]);
    }
  };

  const drawAccordion = (title, content, summary, defaultExpanded, onExpandChange) => {
    return (
      <React.Fragment>
        <Grid container>
          <Accordion
            defaultExpanded={defaultExpanded}
            square
            elevation={0}
            className={classes.expansionPanel}
            TransitionProps={{
              timeout: 0,
            }}
            onChange={onExpandChange}
          >
            <AccordionSummary
              expandIcon={<AccordionIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <Typography component="div" className={classes.headline}>
                {title}
              </Typography>
              {summary && summary}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>{content}</Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <div className={classes.divider} />
      </React.Fragment>
    );
  };

  const drawBrandFilter = () => {
    const title = t("productFilterBrand");
    const render = ({ key, count }) => (
      <Grid key={key} item xs={12}>
        <FormControlLabel
          className={classes.filterCheckbox}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <Checkbox
              checked={brandsSelected[key]}
              onChange={(e) => {
                const update = { ...brandsSelected };
                update[key] = e.target.checked;
                setBrandsSelected(update);
              }}
              name={key}
            />
          }
          label={
            <p>
              {key}
              {!hideCounts && <span className={classes.count}>{count}</span>}
            </p>
          }
        />
      </Grid>
    );

    const brandFilter = (categoryEntry, searchString) =>
      categoryEntry.key.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    const content = (
      <div className={classes.accordionContent}>
        <ListWithFilter
          key={`brandFilter-content-${brandAccordionExpanded ? "expanded" : "notExpanded"}`} // needed for defaultSearchValue to work
          items={availableBrands}
          render={render}
          defaultSearchValue={brandSearchValue}
          onSearchValueChange={(v) => setBrandSearchValue(v)}
          filter={brandFilter}
          textFieldLabel={t("productFilterBrandFilter")}
        />
      </div>
    );
    const summary = (
      <div className={classes.accordionPreview}>
        <ListWithFilter
          key={`brandFilter-summary-${brandAccordionExpanded ? "expanded" : "notExpanded"}`} // needed for defaultSearchValue to work
          items={availableBrands}
          maxItems={5}
          render={render}
          defaultSearchValue={brandSearchValue}
          onSearchValueChange={(v) => setBrandSearchValue(v)}
          filter={brandFilter}
          textFieldLabel={t("productFilterBrandFilter")}
        />
        <div className={classes.showMore}>
          <PlusIcon className={classes.showMoreIcon} />
          {t("productFilterShowMore")}
        </div>
      </div>
    );

    const onExpandChange = () => setBrandAccordionExpanded(!brandAccordionExpanded);
    return drawAccordion(
      title,
      content,
      brandAccordionExpanded ? null : summary,
      brandAccordionExpanded,
      onExpandChange
    );
  };

  const drawCategoryFilter = () => {
    const title = t("productFilterCategory");
    const bikeSynonymsRegEx = new RegExp("rad|räd");
    const categoryFilter = (categoryEntry, searchString) => {
      const normalizedEntry = categoryEntry.key.toLowerCase().replace("-", "");
      const normalizedSearchString = searchString.replace("-", "").toLowerCase();
      if (bikeSynonymsRegEx.test(normalizedSearchString)) {
        return (
          bikeSynonymsRegEx.test(normalizedEntry.split(" > ").pop()) ||
          normalizedEntry.indexOf(normalizedSearchString) >= 0
        );
      }
      return normalizedEntry.indexOf(normalizedSearchString) >= 0;
    };
    const content = (
      <ListWithFilter
        items={availableCategories}
        render={({ key, count }) => (
          <Button
            key={key}
            className={`${classes.categoryButton} ${categoriesSelected[key] ? classes.categoryButtonActive : ""}`}
            onClick={() => {
              const update = { ...categoriesSelected };
              update[key] = categoriesSelected[key] ? !categoriesSelected[key] : true;
              setCategoriesSelected(update);
            }}
          >
            <span className={classes.categoryName}>
              {key
                .split(">")
                .pop()
                .trim()}
            </span>
            {count && !hideCounts && (
              <span className={`${classes.categoryCount} FX-ProductFilterCategoryCount`}>{count}</span>
            )}
          </Button>
        )}
        filter={categoryFilter}
        textFieldLabel={t("productFilterCategoryFilter")}
      />
    );
    return drawAccordion(title, content, null, true, () => {});
  };

  return (
    <>
      <Button
        key="FX-ProductFilter-button"
        className={buttonClass ? buttonClass : ""}
        startIcon={<FilterIcon />}
        onClick={() => {
          setExpanded(true);
          Analytics.productFilter("notSet");
        }}
        data-test="product-filter-open"
      >
        {t("productFilterTitle")}
      </Button>
      <Drawer
        key="FX-ProductFilter-drawer"
        className={classes.drawer}
        anchor="left"
        open={expanded}
        onClose={() => setExpanded(false)}
      >
        {expanded && (
          <div className={classes.drawerContent} data-test="product-filter-container">
            <Grid container className={classes.settingContainer}>
              <Typography component="div" className={classes.headline}>
                {t("productFilterSorting")}
              </Typography>
              <div className={classes.sortContainer}>
                <Button
                  className={`${classes.button} ${classes.sortItem} ${classes.sortItemDiscount} ${
                    sorting === "discount" ? classes.sortItemDiscountActive : ""
                  }`}
                  onClick={() => setSorting("discount")}
                >
                  {t("productFilterSortDiscount")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.sortItem} ${
                    sorting === "popular" ? classes.sortItemActive : ""
                  }`}
                  onClick={() => setSorting("popular")}
                >
                  {t("productFilterSortPopular")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.sortItem} ${
                    sorting === "priceAsc" ? classes.sortItemActive : ""
                  }`}
                  onClick={() => setSorting("priceAsc")}
                >
                  {t("productFilterSortPriceAsc")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.sortItem} ${
                    sorting === "priceDesc" ? classes.sortItemActive : ""
                  }`}
                  onClick={() => setSorting("priceDesc")}
                >
                  {t("productFilterSortPriceDesc")}
                </Button>
              </div>
            </Grid>
            <div className={classes.divider} />

            {showProductConditionFilter && (
              <>
                <Grid container className={classes.settingContainer}>
                  <Typography component="div" className={classes.headline}>
                    {t("productFilterCondition")}
                  </Typography>
                  <div className={classes.sortContainer}>
                    <Button
                      className={`${classes.button} ${classes.sortItem} ${condition ? "" : classes.sortItemActive}`}
                      onClick={() => setCondition(null)}
                    >
                      {t("productFilterConditionAll")}
                    </Button>
                    <Button
                      className={`${classes.button} ${classes.sortItem} ${
                        condition === "new" ? classes.sortItemActive : ""
                      }`}
                      onClick={() => setCondition("new")}
                    >
                      {t("productFilterConditionNew")}
                    </Button>
                    <Button
                      className={`${classes.button} ${classes.sortItem} ${
                        condition === "refurbished" ? classes.sortItemActive : ""
                      }`}
                      onClick={() => setCondition("refurbished")}
                    >
                      {t("productFilterConditionRefurbished")}
                    </Button>
                  </div>
                </Grid>
                <div className={classes.divider} />
              </>
            )}

            <Grid container className={classes.settingContainer}>
              <Typography component="div" className={classes.headline}>
                {t("productFilterPrice")}
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} className={classes.priceSliderContainer}>
                  <StyledSlider
                    min={minimumPrice}
                    max={maximumPrice}
                    value={priceRange}
                    onChange={handleSliderChangePrice}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={priceValuetext}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Input
                    className={classes.priceInput}
                    value={priceRange[0]}
                    margin="dense"
                    onChange={handleInputChangePriceMin}
                    onBlur={handleBlurPriceMin}
                    inputProps={{
                      step: 100,
                      min: minimumPrice,
                      max: maximumPrice,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                    disableUnderline
                  />
                </Grid>
                <Grid item xs={6} md={4} />
                <Grid item xs={3} md={4} container justifyContent="flex-end">
                  <Input
                    className={classes.priceInput}
                    value={priceRange[1]}
                    margin="dense"
                    onChange={handleInputChangePriceMax}
                    onBlur={handleBlurPriceMax}
                    inputProps={{
                      step: 10,
                      min: minimumPrice,
                      max: maximumPrice,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                    disableUnderline
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.divider} />

            {availableBrands && availableBrands.length > 0 && drawBrandFilter()}

            {environment.features.enableCategoryFilterOption && availableCategories.length > 1 && drawCategoryFilter()}

            <div className={classes.showButtonContainer}>
              <Button className={classes.resetButton} onClick={() => reset()}>
                {t("productFilterReset")}
              </Button>
              <Button
                className={classes.showButton}
                endIcon={<OpenIcon className={classes.showButtonIcon} />}
                onClick={() => show()}
                data-test="product-filter-apply"
              >
                {t("productFilterShow")}
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default ProductFilter;
