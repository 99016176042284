import React, { useState } from "react";
import TextFieldAsyncAction from "./TextFieldAsyncAction";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  searchInput: {
    paddingBottom: 16,
  },
}));

const getDisplayedItems = (items, max) => {
  if (max) {
    return items.slice(0, max);
  } else {
    return items;
  }
};

const filterItems = (items, filterFn, value) => {
  if (!items) {
    return [];
  }
  if (!value || !filterFn) {
    return items;
  }
  return items.filter((item) => filterFn(item, value));
};

const ListWithFilter = (props) => {
  const {
    className,
    items,
    filter,
    render,
    textFieldLabel,
    maxItems,
    hideTextField,
    defaultSearchValue,
    onSearchValueChange,
  } = props;
  const classes = useStyles();
  const filterThreshold = 5;
  const filterFn = filter ? filter : () => true;
  const [displayedItems, setDisplayedItems] = useState(
    getDisplayedItems(filterItems(items, filterFn, defaultSearchValue), maxItems)
  );

  if (!items) {
    return null;
  }

  return (
    <div className={className}>
      {!hideTextField && items.length >= filterThreshold && (
        <div className={`FX-ListWithFilter-Content ${classes.searchInput}`}>
          <TextFieldAsyncAction
            className="FX-ListWithFilter-SearchInput"
            defaultValue={defaultSearchValue}
            label={textFieldLabel}
            action={async (value) => {
              const newItems = filterItems(items, filterFn, value);
              setDisplayedItems(getDisplayedItems(newItems, maxItems));
              onSearchValueChange && onSearchValueChange(value);
            }}
          />
        </div>
      )}
      <div className="FX-ListWithFilter-Content">{displayedItems.map((item) => render(item))}</div>
    </div>
  );
};

export default ListWithFilter;
