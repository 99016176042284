export type BuildProductQueryProps = {
  offset?: number;
  stepSize?: number;
  searchString?: string;
  categoryPath?: string;
  categories?: string[];
  categoryFilter?: string;
  selectedBrand?: string;
  brands?: string[];
  sorting?: string;
  condition?: string;
  priceRange?: number[];
  advertiser?: string;
  advertiserQuery?: string | string[];
  advertiserCategory?: string;
  filterSale?: boolean;
};

export function buildProductQuery(props: BuildProductQueryProps) {
  const query = {
    action: "search",
    visibility: "public",
    offset: props.offset,
    size: props.offset + props.stepSize,
  } as any;

  if (props.searchString) {
    query.searchString = props.searchString;
  }
  if (props.categoryPath) {
    query.category = props.categoryPath;
  }
  if (props.categories?.length > 0) {
    query.categories = props.categories.join(",");
  }
  if (props.categoryFilter) {
    query.categoryFilter = props.categoryFilter;
  }
  if (props.selectedBrand) {
    query.brands = props.selectedBrand;
  }
  if (props.brands && props.brands.length > 0) {
    query.brands = props.brands.join(",");
  }
  if (props.sorting) {
    query.sort = props.sorting;
  }
  if (props.condition) {
    query.productCondition = props.condition;
  }
  if (props.priceRange && props.priceRange.length >= 1) {
    query.priceMin = props.priceRange[0];
  }
  if (props.priceRange && props.priceRange.length >= 2) {
    query.priceMax = props.priceRange[1];
  }
  if (props.advertiser || props.advertiserQuery) {
    query.advertiser = props.advertiser ? props.advertiser : props.advertiserQuery;
  }
  if (props.advertiserCategory) {
    query.advertiserCategory = props.advertiserCategory;
  }
  if (props.filterSale) {
    query.sale = props.filterSale;
  }

  return query;
}
