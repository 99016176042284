import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  category: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: 10,
    paddingTop: 9,
    paddingBottom: 7,
    paddingRight: 10,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  name: {
    fontWeight: 600,
  },
  count: {
    marginLeft: 5,
    color: theme.unflashyColor,
  },
}));

const ProductFilterSubmenuButton = (props) => {
  const { name, count, className, onClick, startIcon } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.category} ${className ? className : ""}`} onClick={onClick}>
      {startIcon && startIcon}
      <span className={classes.name}>{name}</span>
      {count && <span className={classes.count}>{count}</span>}
    </div>
  );
};

export default ProductFilterSubmenuButton;
