import React, { useRef } from "react";

import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  container: {
    display: "flex",
    overflow: "auto",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  items: {
    flex: "0 0 auto",
  },
  moreContainer: {
    width: "100%",
    textAlign: "right",
    // [theme.breakpoints.down("sm")]: {
    //     paddingRight: theme.globalMarginMobile
    // }
  },
  moreButton: {
    cursor: "pointer",
    // fontSize: 16,
    lineHeight: "20px",
    paddingBottom: 8,
  },
  moreButtonIcon: {
    float: "right",
    marginLeft: 5,
    display: "block",
    height: 20,
    width: 14,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: "url('/images/Mehr-Pfeil.svg')",
  },
}));

const ComponentList = (props) => {
  const { components, className } = props;
  const classes = useStyles();
  const isMobileView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.down("xs"));
  const { t } = useTranslation("common");
  const scroller = useRef(null);
  const scrollMoreStep = 300;

  const showMore = () => {
    const { scrollLeft } = scroller.current;
    const newScroll = scrollLeft + scrollMoreStep;
    const smoothscroll = (start, end) => {
      const stepsize = 40;
      const interval = 20;
      setTimeout(function() {
        if (start + stepsize >= end) {
          scroller.current.scrollLeft = end;
        } else {
          scroller.current.scrollLeft = start + stepsize;
          smoothscroll(start + stepsize, end);
        }
      }, interval);
    };
    smoothscroll(scroller.current.scrollLeft, newScroll);
  };

  return (
    <React.Fragment>
      {isMobileView && components && components.length > 3 && (
        <div className={classes.moreContainer}>
          <div className={classes.moreButton} onClick={() => showMore()}>
            {t("cardHighLightsMore")}
            <i className={classes.moreButtonIcon} />
          </div>
        </div>
      )}
      <div ref={scroller} className={`${classes.container} ${className ? className : ""}`}>
        {components &&
          components.map((c, i) => (
            <div key={i} className={classes.items}>
              {c}
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default ComponentList;
