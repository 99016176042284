import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textfield: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

const TextFieldAsyncAction = (props) => {
  const { className, label, action, defaultValue } = props;
  const classes = useStyles();
  var timer;

  const onChangeHandler = (value) => {
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(async () => {
      action && action(value);
    }, 150);
  };

  return (
    <TextField
      color="secondary"
      defaultValue={defaultValue}
      className={`${className ? className : ""} ${classes.textfield}`}
      label={label}
      variant="outlined"
      margin="dense"
      onClick={(event) => event.stopPropagation()}
      onChange={(e) => {
        const value = e.target.value;
        onChangeHandler(value);
      }}
    />
  );
};

export default TextFieldAsyncAction;
