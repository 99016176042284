import { FC, ReactNode } from "react";

import { SearchBarRecommendationsNotFound } from "./Content/SearchBar/SearchBarCardComponents";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  center: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerErrorText: {
    display: "flex",
    alignItems: "center",
    color: theme.saleColor,
    fontFamily: theme.highlightFontFamily,
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "23px",
    margin: "20px",
  },
}));

type CycleverseNoContentCompType = {
  messageChildren: ReactNode | string;
  dataTest?: string;
};
export const CycleverseNoContentComp: FC<CycleverseNoContentCompType> = ({ messageChildren, children, dataTest }) => {
  const classes = useStyles();
  return (
    <div className={classes.center} data-test={dataTest || "SearchBarRecommendationsNotFoundComp"}>
      <SearchBarRecommendationsNotFound />
      <div className={classes.centerErrorText}>{messageChildren}</div>
      {children}
    </div>
  );
};
