import React, { useEffect, useState } from "react";

import { buildProductQuery } from "./ProductQueries";
import Analytics from "../../../lib/analytics/analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CycleverseNoContentComp } from "src/components/CycleverseNoContent";
import Grid from "@material-ui/core/Grid";
import ProductCategorySubCategories from "./ProductCategorySubCategories";
import ProductFeed from "./ProductFeed";
import ProductFilter from "./ProductFilter";
import Head from "next/head";
import ProductFilterActiveBar from "./ProductFilterActiveBar";
import { TheCycleverseTheme } from "src/theme";
import environment from "../../../environment";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import { useRouter } from "next/router";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productFeedWithFilterContent: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.globalMarginDesktop,
      marginRight: theme.globalMarginDesktop,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.globalMarginMobile,
      marginRight: theme.globalMarginMobile,
    },
  },
  filterSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.filterColor,
    width: "100%",
  },
  count: {
    textAlign: "center",
    marginTop: 8,
  },
  noMatch: {
    textAlign: "center",
    color: theme.saleColor,
  },
}));

const getAsInt = (input) => {
  if (!input) {
    return undefined;
  }
  if (Number.isInteger(input)) {
    return input;
  } else {
    return parseInt(input, 10);
  }
};

const defaultPriceRange = [environment.products.minimumPrice, environment.products.maximumPrice];
const defaultCondition = null;

const ProductFeedWithFilter = (props) => {
  const {
    prefetchedContent,
    loadingComponent,
    category,
    categoryFilter,
    advertiser,
    advertiserCategory,
    filterSale,
    defaultSort,
    feedClassName,
    className,
    onApplyFilter,
    fixedSearchString,
    selectedBrand,
    disableLazyLoad,
    onNext,
    onPrev,
  } = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("common");
  const stepSize = 40;
  const categoryPath = category ? category.pathNames.join(" > ") : null;
  const router = useRouter();
  const { s, o, p, b, c, a, d, page, dp } = router.query;
  const omitDetailPage = dp == "omit";
  const advertiserQuery = a;
  const [sorting, setSorting] = useState(o ? o : defaultSort);
  const [condition, setCondition] = useState(d ? d : defaultCondition);
  const [priceRange, setPriceRange] = useState(
    p
      ? decodeURIComponent(p as string)
          .split(",")
          .map((s) => parseInt(s))
      : defaultPriceRange
  );
  const [brands, setBrands] = useState(b ? decodeURIComponent(b as string).split(",") : []);
  const [categories, setCategories] = useState(c ? decodeURIComponent(c as string).split(",") : []);
  const [searchString, setSearchString] = useState(fixedSearchString ? fixedSearchString : s);
  const [offset, setOffset] = useState(page ? Math.max(0, (getAsInt(page) - 1) * stepSize) : 0);
  const [initialAvailableCategories, setInitialAvailableCategories] = useState<string[]>();
  const [initialAvailableBrands, setInitialAvailableBrands] = useState<string[]>();

  const searchId = `${sorting}${condition}${priceRange}${brands}${searchString}${
    initialAvailableCategories ? initialAvailableCategories.join("") : ""
  }${initialAvailableBrands ? initialAvailableBrands.join("") : ""}`;

  const isFilterEnabled =
    brands.length > 0 ||
    categories.length > 0 ||
    !!condition ||
    (priceRange ? priceRange.join("") : "") != defaultPriceRange.join("") ||
    sorting != defaultSort;

  useEffect(() => {
    setSorting(o ? o : defaultSort);
    setCondition(d ? d : defaultCondition);
    setPriceRange(
      p
        ? decodeURIComponent(p as string)
            .split(",")
            .map((s) => parseInt(s))
        : defaultPriceRange
    );
    setBrands(b ? decodeURIComponent(b as string).split(",") : selectedBrand ? [selectedBrand] : []);
    setCategories(c ? decodeURIComponent(c as string).split(",") : []);
    setSearchString(fixedSearchString ? fixedSearchString : s);
    setOffset(page ? Math.max(0, (getAsInt(page) - 1) * stepSize) : 0);
  }, [o, p, b, c, s, d, defaultSort, fixedSearchString, page, selectedBrand]);

  if (fixedSearchString && searchString != fixedSearchString) {
    setSearchString(fixedSearchString);
    setCategories(c ? decodeURIComponent(c as string).split(",") : []);
    setBrands(b ? decodeURIComponent(b as string).split(",") : []);
    setSorting(defaultSort);
    setCondition(d ? d : defaultCondition);
    setPriceRange(
      p
        ? decodeURIComponent(p as string)
            .split(",")
            .map((s) => parseInt(s))
        : defaultPriceRange
    );
    setOffset(page ? Math.max(0, (getAsInt(page) - 1) * stepSize) : 0);
    setInitialAvailableCategories(null);
    setInitialAvailableBrands(null);
  }

  const { data } = useSWR([
    queryProducts,
    JSON.stringify(
      buildProductQuery({
        offset,
        stepSize,
        searchString,
        categoryPath,
        categories,
        categoryFilter,
        selectedBrand,
        brands,
        sorting,
        condition,
        priceRange,
        advertiser,
        advertiserQuery,
        advertiserCategory,
        filterSale,
      })
    ),
  ]);
  const content = data?.queryProducts ?? prefetchedContent;

  useEffect(() => {
    if (data) {
      const totalCount = data?.queryProducts?.total;
      if (searchString) {
        if (totalCount <= 0) {
          Analytics.productSearchNoResults(searchString);
        } else {
          Analytics.productSearch(searchString, totalCount);
        }
      }
    }
  }, [searchString, data]);

  if (!content?.items) {
    if (loadingComponent) {
      return loadingComponent;
    }
    return (
      <div style={{ textAlign: "center", margin: 20 }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const products = content.items;
  const totalCount = content.total;
  const availableCategories = content.categories;
  const availableBrands = content.brands;
  const resultIsInaccurate = false; //disable for now //content.inaccuracy && content.inaccuracy > 0;

  if (availableCategories && !initialAvailableCategories) {
    setInitialAvailableCategories(availableCategories);
  }

  if (availableBrands && !initialAvailableBrands) {
    setInitialAvailableBrands(availableBrands);
  }

  const applyFilter = (params) => {
    setCategories(params.categories);
    setBrands(params.brands);
    setSorting(params.sorting);
    setCondition(params.condition);
    setPriceRange(params.priceRange);
    if (fixedSearchString) {
      onApplyFilter({
        ...params,
        searchString: fixedSearchString,
      });
    } else {
      onApplyFilter(params);
    }
    setInitialAvailableCategories(null);
    setInitialAvailableBrands(null);
  };

  const reset = () => {
    setOffset(0);
    setInitialAvailableCategories(null);
    setInitialAvailableBrands(null);
    applyFilter({
      categories: [],
      brands: [],
      sorting: defaultSort,
      condition: defaultCondition,
      priceRange: defaultPriceRange,
    });
  };

  const getLink = (type) => {
    const pathname = router.pathname;
    const query: any = { ...router.query };
    const currentPage = query.page ? getAsInt(query.page) : 1;
    if (type == "next") {
      query.page = currentPage + 1;
    } else if (type == "prev") {
      query.page = currentPage - 1;
      if (query.page <= 1) {
        delete query.page; // for canonical handling, page 1 is default
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn("Unknown pagination link type");
    }
    return {
      pathname,
      query,
    };
  };

  return (
    <>
      {omitDetailPage && (
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
      )}
      <div className={className}>
        <Grid container className={classes.filterSection}>
          <Grid xs={6} sm={2} item>
            <ProductFilter
              buttonClass={classes.filterButton}
              availableBrands={initialAvailableBrands}
              availableCategories={initialAvailableCategories}
              hideCounts={resultIsInaccurate}
              values={{ sorting, condition, priceRange, brands, categories }}
              onFilter={(params) => {
                applyFilter(params);
              }}
              onReset={() => reset()}
            />
          </Grid>
          <Grid xs={6} sm={3} item>
            <div className={classes.count}>{t("productCategoryCount", { totalCount })}</div>
          </Grid>
        </Grid>
        {isFilterEnabled && (
          <ProductFilterActiveBar
            values={{ sorting, condition, priceRange, brands, categories }}
            onFilter={(params) => {
              applyFilter(params);
            }}
            onReset={() => reset()}
            defaultSort={defaultSort}
          />
        )}
        {!isFilterEnabled && category && (
          <ProductCategorySubCategories category={category} availableCategories={availableCategories} />
        )}
        {totalCount <= 0 && (
          <CycleverseNoContentComp
            messageChildren={
              <p>
                {t("productCategoryNoMatchCTA1")}
                <br /> {t("productCategoryNoMatchCTA2")}
              </p>
            }
          />
        )}
        <ProductFeed
          products={products}
          loadMore={(offset) => setOffset(offset)}
          stepSize={stepSize}
          offset={offset}
          total={totalCount}
          className={feedClassName}
          searchId={searchId}
          showBioBikeFinderCard={categoryPath == "Fahrräder"}
          showEBikeFinderCard={categoryPath == "E-Bikes"}
          showKidsBikeFinderCard={categoryPath == "Fahrräder > Kinder- & Jugendräder"}
          showArticleTeaser={false}
          nextLink={getLink("next")}
          prevLink={getLink("prev")}
          omitDetailPage={omitDetailPage}
          disableLazyLoad={disableLazyLoad}
          onNext={onNext}
          onPrev={onPrev}
        />
      </div>
    </>
  );
};

export default ProductFeedWithFilter;
